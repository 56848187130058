import React from "react";

export const ContactForm = () => {
  return (
    <form method="post" name="contact-form" data-netlify="true" data-netlify-honeypot="bot-field">
      <div className="form">
        <div className="form-group">
          <label htmlFor="name">Jméno a Příjmení</label>
          <input type="text" name="name" id="name" placeholder="Napiš jak se jmenuješ…" required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" name="email" id="email" placeholder="jan@novak.cz" required />
        </div>
        <div className="form-group full-width">
          <label htmlFor="message">Zpráva</label>
          <textarea name="message" id="message" placeholder="A sem zprávu…" required />
        </div>
        <button type="submit">Odeslat</button>
      </div>
    </form>
  );
};

import React from "react";
import { graphql, PageProps } from "gatsby";
import { CategoryPage } from "../components/category/CategoryPage";
import { PageLayout } from "../components/PageLayout";
import { Helmet } from "react-helmet";

const CategoryPageTemplate = (props: PageProps) => {
  const { title, image, collection } = (props.data as any).markdownRemark.frontmatter;
  return (
    <PageLayout>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CategoryPage title={title} categoryImage={image.childImageSharp.fluid} collectionKey={collection} />
    </PageLayout>
  );
};

export default CategoryPageTemplate;

export const pageQuery = graphql`
  # $slug comes from context defined in gatsby-node.js
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 800, quality: 40) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        collection
      }
    }
  }
`;

import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img, { FluidObject } from "gatsby-image";

interface NewsQueryResult {
  title: string;
  imageFluid: FluidObject;
  date: Date;
  description: string;
  slug: string;
}

function useDataFetch(): NewsQueryResult[] {
  const queryData = useStaticQuery(graphql`
    query BlogQuery {
      allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "blog" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 40) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
            description
          }
        }
      }
    }
  `);

  return queryData.allMarkdownRemark.nodes.map((node) => {
    const { title, image, date, description } = node.frontmatter;
    return {
      title,
      imageFluid: image.childImageSharp.fluid,
      date: new Date(date),
      description,
      slug: node.fields.slug,
    };
  });
}

export const BlogListView = () => {
  const data = useDataFetch();
  return (
    <>
      <div className="news-list">
        {data.map((blogPost) => (
          <Link className="news-item" to={blogPost.slug} key={blogPost.slug}>
            <h4>{blogPost.title}</h4>
            <div className="news-item-content">
              <div className="image">
                <Img fluid={{ ...blogPost.imageFluid, aspectRatio: 1.78 }} alt={blogPost.title} />
              </div>
              <p>{blogPost.description}</p>
            </div>
            <div className="news-item-overlay">
              <div className="overlay-link">Přečíst</div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

import React, { useContext } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import QuestionMark from "../../img/otaznik_zrout.svg";
import Pen from "../../img/vykricnik_sbijecka.svg";
import { AppContext } from "../../contexts/AppContext";

export const Categories = () => {
  const queryData = useStaticQuery(graphql`
    query CategoriesQuery {
      allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "category" } } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        nodes {
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 40) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  `);

  const data: {
    title: string;
    imageFluidObj: FluidObject;
    slug: string;
  }[] = queryData.allMarkdownRemark.nodes.map((node) => ({
    title: node.frontmatter.title,
    imageFluidObj: node.frontmatter.image.childImageSharp.fluid,
    slug: node.fields.slug,
  }));

  const { isMobile } = useContext(AppContext);

  return (
    <div className="categories">
      <div className="container-fixed">
        <div className="categories-wrapper">
          {data.map((category, i) => (
            <Link to={category.slug.replace("/kategorie", "")} key={i} className="category">
              <div className="category-text">
                <span>{category.title}</span>
              </div>
              <div className="category-image">
                <Img
                  fluid={{ ...category.imageFluidObj, aspectRatio: isMobile ? 1.66 : 1 }}
                  alt={category.title}
                />
                {i === 1 && (
                  <div className="category-image--question">
                    <QuestionMark />
                  </div>
                )}
                {i === 2 && (
                  <div className="category-image--pen">
                    <Pen />
                  </div>
                )}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
